import {Injectable} from '@angular/core';
import {concatAll, concatMap, forkJoin, from, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {v4 as uuidv4} from 'uuid';
import {GlobalSettings} from '../models/global-settings';
import {ApiService} from './api.service';

function completeBackgroundGenerationPrompt(
  mainPrompt: string,
  numberOfImages: string,
) {
  return `### Context:
${mainPrompt}

### Additional requirements:
  Please suggest ${numberOfImages} prompts

### Output format:
  JSON format like below:
{"prompt": "the prompt for one background image."}`;
}

function completeValidatingBackgroundPrompt(
  campaignDetails: string,
  companySpecificRules: string,
  imageDescription: string,
  imageFeatures: any,
) {
  return `### Context:
You are a professional display ads reviewer. Can you validate if the image can
be used safely as a display ads with the following information?

### Campaign detail:
${campaignDetails}

### Basic rules:
Cannot contain logo of competitors or other companies.
Cannot show products or services from competitors.
Cannot use any text or slogans used by competitors.
Never put competitors in a negative light.
Focus on the strengths of our own offering.
Do not use absolute superlatives like "the best," "the most effective," or 
"unmatched.". These can be difficult to prove and may alienate potential
customers.
Ensure all claims about your product or service are truthful, accurate, and 
can be substantiated with evidence.
Avoid exaggerations or misleading statements.
Advertising to Children: If your target audience includes children, adhere to
special regulations designed to protect them from manipulative advertising
practices.

### Company specific rules:
${companySpecificRules}

### Image description:
${imageDescription}

### Image annotations:
#### Image labels:
${imageFeatures.labelAnnotations.map((v: any) => v.description)}

#### Objects present in image:
${imageFeatures.localizedObjectAnnotations.map((v: any) => ({
  name: v.name,
  score: v.score,
}))}

#### Corporation logos in image:
 ${imageFeatures.logoAnnotations.map((v: any) => ({
   name: v.description,
   score: v.score,
 }))}

#### Text resent in image;
${imageFeatures.textAnnotations.text ?? ''}

#### Image properties:
${JSON.stringify(imageFeatures.imagePropertiesAnnotation)}

### Output format:
The output should be in JSON, the validationStatus can be one of three status:
SAFE, NOT_SURE, UNSAFE and the validationMessage is the explanation of the
review result.
{
  validationStatus: "the validationStatus",
  validationMessage: "the validationMessage"
}`;
}

function parseRawBackgroundPrompt(rawPrompt: string) {
  const backgroundPrompts = [];
  let match;
  const regex = /['"]prompt['"]:\s+['"](.*)['"]/g;
  while ((match = regex.exec(rawPrompt)) !== null) {
    backgroundPrompts.push(match[1]);
  }
  return backgroundPrompts;
}

function parseRawBackgroundValidationPrompt(rawPrompt: string) {
  return rawPrompt.replace('```json', '').replace('```', '');
}

function buildBackgroundGenerationArg(
  backgroundPrompt: string,
  foregroundUrl: string,
  ratio: string,
) {
  return {
    backgroundPrompt,
    foregroundUrl,
    ratio,
  };
}

function buildOverlayArgs(
  urls: string[],
  foregroundUrl: string,
  prompt: string,
) {
  const overlayArgs: Array<{
    backgroundUrl: string;
    foregroundUrl: string;
    prompt: string;
  }> = urls.map((backgroundUrl: string) => ({
    backgroundUrl,
    foregroundUrl,
    prompt,
  }));
  return overlayArgs;
}

function buildServerEvent(
  composedImageUrl: string,
  backgroundImageUrl: string,
  promptForImageGeneration: string,
) {
  return {
    status: 'processing',
    image: {
      id: uuidv4(),
      expirationDate: new Date(),
      composedImageUrl,
      backgroundImageUrl,
      promptForImageGeneration,
    },
  };
}

/** Business Logic Services. */
@Injectable({
  providedIn: 'root',
})
export class BusinessLogicService {
  constructor(private apiService: ApiService) {}

  getEvents(
    folderName: string,
    prompt: string,
    transparency: number,
    safetyFilterLevel: string,
    language: string,
    personGenerationSetting: string,
    negativePrompt: string,
    aspectRatio: Record<string, string>,
    directGeneration: boolean,
    useSeed: boolean,
    settings: GlobalSettings | null,
  ): Observable<any> {
    const fullPrompt = completeBackgroundGenerationPrompt(
      prompt,
      settings!.numberOfImages.toString(),
    );
    const backgroundPrompts$ = this.apiService
      .generateContent(fullPrompt, settings!.imageGenModelVersion.toString())
      .pipe(concatMap((rawPrompt) => parseRawBackgroundPrompt(rawPrompt)));

    const aspectRatios$ = from(
      Object.entries(aspectRatio).map(([foregroundUrl, ratio]) => ({
        foregroundUrl,
        ratio,
      })),
    );

    const backgroundGenerations$ = backgroundPrompts$.pipe(
      concatMap((prompt) => {
        return aspectRatios$.pipe(
          concatMap((ar) =>
            of(
              buildBackgroundGenerationArg(prompt, ar.foregroundUrl, ar.ratio),
            ),
          ),
        );
      }),
    );

    return backgroundGenerations$.pipe(
      concatMap((bgGenArg) => {
        return this.apiService
          .generateImage(
            folderName,
            bgGenArg.backgroundPrompt,
            transparency,
            safetyFilterLevel,
            language,
            personGenerationSetting,
            negativePrompt,
            bgGenArg.ratio,
            directGeneration,
            useSeed,
            settings,
          )
          .pipe(
            map((data: any) =>
              buildOverlayArgs(
                data.urls,
                bgGenArg.foregroundUrl,
                bgGenArg.backgroundPrompt,
              ),
            ),
          );
      }),
      concatAll(),
      concatMap((overlayArg) => {
        return this.apiService
          .overlayImage(
            folderName,
            overlayArg.foregroundUrl,
            overlayArg.backgroundUrl,
            transparency,
          )
          .pipe(
            map((data: any) =>
              buildServerEvent(
                data.url,
                overlayArg.backgroundUrl,
                overlayArg.prompt,
              ),
            ),
          );
      }),
    );
  }

  validateBackground(
    campaignDetails: string,
    companySpecificRules: string,
    backgroundUrl: string,
    settings: GlobalSettings | null,
  ): Observable<string> {
    console.log('settings', settings);
    return forkJoin({
      imageDescription: this.apiService.generateContent(
        'What is this image?',
        settings!.geminiModelVersion.toString(),
        backgroundUrl,
      ),
      imageAnnotations: this.apiService.annotateImage(backgroundUrl),
    }).pipe(
      concatMap(({imageDescription, imageAnnotations}) => {
        console.log('imageDescription', imageDescription);
        console.log('imageAnnotations', imageAnnotations);

        const fullPrompt = completeValidatingBackgroundPrompt(
          campaignDetails,
          companySpecificRules,
          imageDescription,
          imageAnnotations,
        );
        console.log('fullValidatingPrompt', fullPrompt);

        return this.apiService.generateContent(
          fullPrompt,
          settings!.geminiModelVersion.toString(),
        );
      }),
      map((resp) => parseRawBackgroundValidationPrompt(resp)),
    );
  }

  describeImageBackground(
    backgroundUrl: string,
    settings: GlobalSettings | null,
  ) {
    const prompt = 'Can you describe the background of this image?';
    return this.apiService.generateContent(
      prompt,
      settings!.geminiModelVersion.toString(),
      backgroundUrl,
    );
  }

  resizeImages(urls: string[], width: number, height: number) {
    return this.apiService.resizeImages(urls, width, height).pipe(
      concatMap((data: any) => {
        return this.apiService.downloadZip(data.url);
      }),
    );
  }
}
